<template> 
<div>
    <main class="main">
        <div class="category-banner">
            <div class="container">
                <div class="content-left">
                    <span>EXTRA</span>
                    <h2>20% OFF</h2>
                    <h4 class="cross-txt">BIKES</h4>
                </div>
                <div class="content-center">
                    <img src="assets/images/banners/category_banner.png">
                </div>
                <div class="content-right">
                    <p>Summer Sale</p>
                    <button class="btn">Shop All Sale</button>
                </div>
            </div>
        </div>
    
        <nav aria-label="breadcrumb" class="breadcrumb-nav">
            <div class="container">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Wishlist</li>
                </ol>
            </div>
        </nav>
        <div class="container">
            <div class="row row-sm">
                <div class="col-lg-9">

                    <div class="row row-sm">
                        <div class="col-6 col-md-4 col-xl-3" v-for="item in wishlist" :key="item.id">
                            <div class="product-default inner-quickview wishlist-box">
                                <figure class="h-50 w-100">
                                    <a v-bind:href="'/product/'+item.product.id">
                                        <img class="h-100" :src="base_url+'/api/public/file/download/' + item.product.imageUrl">
                                    </a>
                                    <div class="label-group" v-if="item.product.isSale">
                                        <div class="product-label label-hot"></div>
                                        <div class="product-label label-sale">Sale</div>
                                    </div>
                                </figure>
                                <div class="product-details wishlist-box-details">
                                    <h3 style="font-weight:400">
                                        <a :href="'/product/'+item.product.id">{{ item.product.name }}</a>
                                    </h3>
                                    <h2 class="product-title" v-if="item.product.stockQuantity > 9">
                                        stock: {{ item.product.stockQuantity }} left
                                    </h2>
                                    <h2 class="product-title" v-else-if="item.product.stockQuantity < 10 && item.product.stockQuantity > 0">
                                        stock: only {{ item.product.stockQuantity }} left
                                    </h2>
                                    <h2 class="product-title" v-else-if="item.product.stockQuantity === 0">
                                        stock: out of stock
                                    </h2>
                                    <div class="price-box" v-if="item.product.isSale">
                                        <span class="old-price">{{item.product.price+" LYD"}} </span>
                                        <span class="product-price">{{ item.product.salePrice+" LYD" }} </span>
                                    </div>
                                    <div class="price-box" v-else>
                                        <span class="product-price">{{ item.product.price }} LYD</span>
                                    </div><!-- End .price-box -->
                                    <button type="button" class="btn btn-primary btn-block" @click.prevent="delete_wishlist(item.id)">Delete</button>
                                </div><!-- End .product-details -->
                            </div>
                        </div>

                    </div><!-- End .row -->

                    <pagination style="direction: rtl; text-align: right;" v-model="page" :records="countProducts" @paginate="setPage" ref="bottomPagination"/>

                </div><!-- End .col-lg-9 -->
            </div><!-- End .row -->
        </div><!-- End .container -->

            <div class="mb-5"></div><!-- margin -->
        </main><!-- End .main -->

</div>
</template>
<style>

</style>

<script>
export default {
    data(){
        return {
            wishlist    : [],
            countProducts   : 0,
            currentPage : 0,
            page : 1,
            firstPage   : 0,
            LastPage    : 0,
            nextPage    : 0,
            previosPage : 0,
            base_url : process.env.VUE_APP_URL,
			}
    },
    mounted () {
        this.axios
        .get(process.env.VUE_APP_URL+'/api/favorites',{
            headers: {
            'Authorization': 'Bearer '+localStorage.getItem('token')
        }})
        .then(response => {
            this.wishlist = response.data
        })
    },
    methods : {
        setPage: function(page) {
            this.axios
            .get(process.env.VUE_APP_URL+'/api/favorites?page='+page+'&size=16',{
                headers: {
                'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                this.wishlist = response.data
            })
            
            if (page === this.page) {
                 /* prevent stack overflow */ return; 
            }
            this.page = page;
            this.$refs.bottomPagination.setPage(page);
            this.$refs.topPagination.setPage(page);
        },
        convertToHtml: function(content){
        var dom = document.createElement('div');
        dom.innerHTML = content;
        return content;
        },
        delete_wishlist: function(id) {
            this.axios
                .delete(process.env.VUE_APP_URL+`/api/favorites/${id}`, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then(() => this.$router.go())
            
        }
    }
}
</script>

<style scoped>
    .wishlist-box {
        background: white;
        border: 1px solid #ebebeb;
        border-radius: 0.5rem;
        height: 400px;
        display: flex;
        flex-wrap: wrap;
        align-items: space-between;
        justify-content: space-between;
    }
    .wishlist-box-details {
        width: 250px;
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        flex-direction: column;
        overflow: hidden;
    }
    .product-default figure img:first-child {
        width: 100% !important;
    }
    .inner-quickview .product-details {
        padding-bottom: 5px !important;
        width: 90%;
        margin-left: 15px;
    }
</style>